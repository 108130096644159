import React from "react"
import PropTypes from "prop-types"
import SEO from "../seo"
import Layout from "../layout"
import LedeArticles from "./lede-articles"
import SearchBox from "./searchbox"
import SearchResultsLayout from "../search/search-results-layout"
import { connectStateResults } from "react-instantsearch-dom"
import { useUser } from "../../context/user-context"
import SidebarLayout from "../../components/sidebar/layout"
import ArticleSections from "./article-sections"
import ErrorBoundary from "../error-boundary"

function ArticlesLayout({
  searchState,
  searchResults,
  featured,
  articles,
  userHasArticles,
  seo_title,
  seo_description,
  categories,
  partnerOffersTitle,
  partnerOffers,
}) {
  const user = useUser()
  let ledeArticles
  let bookmarks = []

  // If user has saved articles show them at the top, if not, show featured articles
  if (userHasArticles) {
    ledeArticles = user.articles
    bookmarks = user.articles
  } else {
    ledeArticles = featured
  }

  // Make sure we have articles to show at the top and render them in LedeArticles component
  const lede = ledeArticles && ledeArticles.length && (
    <LedeArticles articles={ledeArticles} userHasArticles={userHasArticles} />
  )

  const allArticles = articles && (
    <ErrorBoundary additionalClasses="mx-auto mt-16">
      <ArticleSections
        categories={categories}
        featured={featured}
        bookmarks={bookmarks}
        userHasArticles={userHasArticles}
        partnerOffersTitle={partnerOffersTitle}
        partnerOffers={partnerOffers}
      />
    </ErrorBoundary>
  )

  const searchResultsLayout = (
    <SearchResultsLayout
      searchState={searchState}
      searchResults={searchResults}
      user={user}
      lede={lede}
      additionalClasses="lantern-container px-6 md:px-0 mb-4"
      allArticles={allArticles}
    />
  )

  let articlesPageContent = (
    <>
      <SEO
        keywords={[`end`, `life`, `funeral`, `planning`, `lantern`]}
        title={seo_title}
        description={seo_description}
        excludeSiteMetadataTitle
      />

      <div className="py-12 xl:px-0">
        <div className="lantern-container mb-6">
          <div className="text-4xl md:text-5xl leading-tight font-extrabold w-full pb-4 pt-4 md:pb-0">
            Article Library
          </div>
        </div>
        <div className="lantern-container mb-0">
          <SearchBox />
        </div>
        {searchResultsLayout}
      </div>
    </>
  )

  return (
    <div>
      {user !== null ? (
        <SidebarLayout fullWidth>{articlesPageContent}</SidebarLayout>
      ) : (
        <Layout>
          <div className="bg-grey-lighter">{articlesPageContent}</div>
        </Layout>
      )}
    </div>
  )
}

ArticlesLayout.propTypes = {
  searchState: PropTypes.object,
  searchResults: PropTypes.object,
  title: PropTypes.object,
  hasNextPage: PropTypes.bool,
  featured: PropTypes.array,
  articles: PropTypes.array,
  loadMore: PropTypes.func,
  userHasArticles: PropTypes.bool,
  seo_title: PropTypes.string,
  seo_description: PropTypes.string,
  categories: PropTypes.array,
  partnerOffersTitle: PropTypes.string,
  partnerOffers: PropTypes.array,
}

ArticlesLayout.defaultProps = {
  searchState: {},
  searchResults: {},
  title: {},
  hasNextPage: false,
  featured: [],
  articles: [],
  loadMore: () => {},
  userHasArticles: false,
  seo_title: "",
  seo_description: "",
  partnerOffersTitle: "",
  partnerOffers: [],
}

export default connectStateResults(ArticlesLayout)
