import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ArticlesLayout from "../components/articles/articles-layout"
import LoadingAnimation from "../components/loading-animation"
import Error from "../components/error"
import apolloPrismicClient from "../utils/apollo-prismic-client"
import { useQuery } from "@apollo/client"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch } from "react-instantsearch-dom"
import {
  ARTICLES_QUERY,
  ARTICLES_RELATED_PARTNER_OFFERS_QUERY,
} from "../graphql/queries/articles"
import { useUser } from "../context/user-context"
import { Prismic, client } from "../utils/prismic-api-client"

function Articles() {
  const { allPrismicArticlesPage } = useStaticQuery(
    graphql`
      query ArticlePageQuery {
        allPrismicArticlesPage {
          edges {
            node {
              data {
                title {
                  html
                  text
                }
                body {
                  html
                  text
                }
                partner_offers_title {
                  html
                  text
                }
                seo_title {
                  html
                  text
                }
                seo_description {
                  html
                  text
                }
              }
            }
          }
        }
      }
    `
  )
  const user = useUser()

  const [categories, setCategories] = useState([])
  const [processing, setProcessing] = useState(true)

  const getPageData = async () => {
    const results = await client.getAllByType("category")
    //Get articles related to each category in parallel
    const articleGetter = results?.map(async (cat) => {
      //fetch articles of categores that are featured only
      if (cat.data.featured) {
        const resp = await client.get({
          predicates: [
            Prismic.Predicates.at("document.type", "article"),
            Prismic.Predicates.at("my.article.categories.category", cat.id),
          ],
          pageSize: 4,
        })
        cat.article_response = resp
      }
      return cat
    })
    const categoryArticles = await Promise.all(articleGetter)
    // filtered out show in shop category
    let filteredCategory = categoryArticles.filter((category) => {
      if (category.data.show_in_shop !== true) {
        return category
      }
      return false
    })
    setCategories(filteredCategory)
    setProcessing(false)
  }

  useEffect(() => {
    getPageData()
  }, [])

  const { data, error, loading, fetchMore } = useQuery(ARTICLES_QUERY, {
    client: apolloPrismicClient,
    variables: { after: null },
  })

  const {
    data: relatedPartnerOffersData,
    //  : loadingRelatedPartnerOffers,
  } = useQuery(ARTICLES_RELATED_PARTNER_OFFERS_QUERY, {
    client: apolloPrismicClient,
  })

  if (error)
    return <Error title={"Uh oh! Something went wrong. Please try again."} />
  if (loading || !data) return <LoadingAnimation />

  if (processing) return <LoadingAnimation />

  const relatedPartnerOffers =
    relatedPartnerOffersData?.allArticles_pages?.edges[0].node.partner_offers

  // Get content for /articles page.
  const { title, body, seo_title, seo_description, partner_offers_title } =
    allPrismicArticlesPage.edges[0].node.data

  const { endCursor, hasNextPage } = data.all.pageInfo
  const featured = data.featured.edges
  const articles = data.all.edges

  const loadMore = () => {
    fetchMore({
      variables: { after: endCursor },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.all.edges = [
          ...prevResult.all.edges,
          ...fetchMoreResult.all.edges,
        ]
        return fetchMoreResult
      },
    })
  }

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )

  let userHasArticles =
    user !== null ? (user.articles.length ? true : false) : false

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.GATSBY_ALGOLIA_INDEX}
    >
      <ArticlesLayout
        title={title}
        body={body}
        hasNextPage={hasNextPage}
        featured={featured}
        articles={articles}
        loadMore={loadMore}
        categories={categories}
        userHasArticles={userHasArticles}
        seo_title={seo_title?.text}
        seo_description={seo_description?.text}
        data-testId="articles-layout-id"
        partnerOffersTitle={partner_offers_title?.text}
        partnerOffers={relatedPartnerOffers}
      />
    </InstantSearch>
  )
}

export default Articles
