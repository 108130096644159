import React from "react"
import PropTypes from "prop-types"
import { isBrowser } from "../../utils/browser"
import { formatHeapEvent } from "../../utils/helpers"

const OfferTile = ({ offer, fullWidth, heapEvent }) => {
  let heapAPI
  if (isBrowser() && window.heap) {
    heapAPI = window.heap
  }

  let heapTrackingInfo = formatHeapEvent(heapEvent)
  const offerName = offer?.partner_offer?.name[0]?.text
  const offerDescription = offer?.partner_offer?.description[0]?.text

  return (
    <a
      href={offer?.partner_offer?.link[0]?.text}
      target={offer?.partner_offer?.open_new_tab ? "_blank" : ""}
      rel="noreferrer"
    >
      <div
        className={`${fullWidth ? "pr-4" : "mb-4"} 
          flex flex-row h-full bg-white relative rounded-xl md:shadow-md hover:bg-secondary`}
        id="offer-tile"
        onClick={() =>
          heapAPI.track(heapTrackingInfo, { offer_name: offerName })
        }
      >
        <div
          className={`${fullWidth ? "md:w-1/4" : ""} 
            w-1/2 bg-secondary rounded-l-xl bg-no-repeat bg-right bg-cover border-r-2 border-grey-lighter`}
          style={{
            backgroundImage: `url('${offer?.partner_offer?.image?.url}')`,
          }}
          role="img"
          aria-label={offer?.partner_offer?.image?.alt}
        ></div>

        <div
          className={`${fullWidth ? "md:w-full md:px-6 md:pb-4 md:pt-4" : ""} 
          w-1/2 px-4 pb-3 pt-2 my-auto`}
        >
          <div className="body-text font-semibold leading-tight">
            {offerName}
          </div>
          <div className="text-sm text-grey-dark leading-snug">
            {offerDescription}
          </div>
          {fullWidth && (
            <div className="hidden md:flex text-sm items-center font-semibold text-primary pl-0 cursor-pointer mt-0">
              <span>Learn more</span>
              <i className="material-icons">chevron_right</i>
            </div>
          )}
        </div>
      </div>
    </a>
  )
}

OfferTile.propTypes = {
  offer: PropTypes.object,
  fromTopicQuestion: PropTypes.bool,
  fromAfterLossTask: PropTypes.bool,
  fullWidth: PropTypes.bool,
  heapEvent: PropTypes.object.isRequired,
}

OfferTile.defaultProps = {
  offer: {},
  fromTopicQuestion: false,
}

export default OfferTile
