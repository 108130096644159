import * as Prismic from "@prismicio/client"
import { isBrowser } from "./browser"
import fetch from "cross-fetch"

const apiToken = process.env.GATSBY_PRISMIC_TOKEN
const endpoint = Prismic.getEndpoint("lanternco")
let client = null
if (isBrowser()) {
  client = Prismic.createClient(endpoint, { accessToken: apiToken })
} else {
  client = Prismic.createClient(endpoint, {
    fetch: fetch,
    accessToken: apiToken,
  })
}

export { client, Prismic }
