import React from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import OfferTile from "./tile"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const PartnerOffers = ({ partnerOffers, heapEvent }) => {
  // If partner offers array doesnt exist or if it's empty,
  // throw error that will trigger Error Boundary
  if (!partnerOffers || (partnerOffers && partnerOffers.length === 0)) {
    throw new Error("No partner offers!")
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    className: "lantern-slider",
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="w-full flex flex-row mb-8 md:mb-12">
      <Slider {...settings}>
        {partnerOffers &&
          partnerOffers.map(
            (o, i) =>
              o.partner_offer && (
                <div key={i} className="w-full md:w-1/3 px-2">
                  <div className="bg-white relative rounded-xl h-64 shadow-md mb-4 hover:bg-secondary">
                    <OfferTile offer={o} heapEvent={heapEvent} />
                  </div>
                </div>
              )
          )}
      </Slider>
    </div>
  )
}

PartnerOffers.propTypes = {
  partnerOffers: PropTypes.array.isRequired,
  heapEvent: PropTypes.object.isRequired,
}

PartnerOffers.defaultProps = {
  partnerOffers: [],
}

export default PartnerOffers
