import { gql } from "@apollo/client"

const ARTICLES_QUERY = gql`
  query ArticlesQuery($after: String) {
    all: allArticles(first: 5, after: $after, sortBy: created_at_DESC) {
      edges {
        node {
          title
          subtitle
          image
          _meta {
            uid
            id
          }
          seo_keywords
          seo_title
          seo_description
          seo_canonical_url
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    featured: allArticles(first: 4, where: { featured: true }) {
      edges {
        node {
          title
          subtitle
          image
          _meta {
            uid
            id
          }
        }
      }
    }
  }
`

const ARTICLES_RELATED_PARTNER_OFFERS_QUERY = gql`
  query {
    allArticles_pages {
      edges {
        node {
          partner_offers {
            partner_offer {
              ... on Partner_offer {
                name
                description
                link
                image
                open_new_tab
              }
            }
          }
        }
      }
    }
  }
`

export { ARTICLES_QUERY, ARTICLES_RELATED_PARTNER_OFFERS_QUERY }
