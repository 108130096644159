import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useUser } from "../../context/user-context"
import { cutOffString } from "../../utils/helpers"
import { isBrowser } from "../../utils/browser"
import SaveArticle from "./save-article"
import { RichText } from "prismic-reactjs"

const ArticleCard = ({ article, userHasArticles, titleCap, fromDashboard }) => {
  const articleSlug = article.node ? article.node._meta.uid : ""

  const user = useUser()

  let urlFromDashboard

  if (isBrowser()) {
    urlFromDashboard = window.location.origin + "/articles"
  }

  let articleData, articleTitle

  if (article.title) {
    articleData = article
    articleTitle = article.title
  } else if (article.node) {
    articleData = article.node
    articleTitle = RichText.asText(article.node.title)
  } else {
    articleData = article.data
    articleTitle = RichText.asText(article.data.title)
  }

  const articleCardContent = (
    <div className="flex flex-row md:flex-col">
      <div
        className="bg-secondary h-32 rounded-l-lg md:rounded-t-lg md:rounded-bl-none bg-no-repeat bg-right bg-cover w-1/4 md:w-full border-r-2 md:border-b-2 border-grey-lighter"
        style={{
          backgroundImage: `url('${
            article.node ? article.node.image.url : articleData.image.url ? articleData.image.url : articleData.image
          }')`,
        }}
        role="img"
        aria-label={
          article.node ? article.node.image.alt : articleData.image.alt
        }
      ></div>

      <div className="md:relative flex items-stretch md:flex-col px-4 w-3/4 md:w-full h-32 pt-3">
        <div className="flex-grow text-base h-18 font-semibold leading-snug">
          {cutOffString(articleTitle, titleCap)}
        </div>

        <span className="md:absolute bottom-0 right-0 block md:mr-3 mb-1 ml-auto">
          <SaveArticle
            article={article}
            userHasArticles={userHasArticles}
            user={user}
          />
        </span>
      </div>
    </div>
  )

  return (
    <>
      {!fromDashboard ? (
        <Link to={`/articles/${article.node ? articleSlug : article.uid}`}>
          {articleCardContent}
        </Link>
      ) : (
        <a
          href={
            urlFromDashboard + `/${article.node ? articleSlug : article.uid}`
          }
        >
          {articleCardContent}
        </a>
      )}
    </>
  )
}

ArticleCard.propTypes = {
  article: PropTypes.object,
  handleSaveArticle: PropTypes.func,
  userHasArticles: PropTypes.bool,
  titleCap: PropTypes.number,
  fromDashboard: PropTypes.bool,
}

ArticleCard.defaultProps = {
  article: {},
  handleSaveArticle: () => {},
  userHasArticles: false,
  fromDashboard: false,
}

export default ArticleCard
