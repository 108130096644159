import React from "react"
import PropTypes from "prop-types"
import ArticleCard from "./article-card"
import { useUser } from "../../context/user-context"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { RichText } from "prismic-reactjs"
import { isBrowser } from "../../utils/browser"

const LedeArticles = ({ articles, userHasArticles, fromDashboard }) => {
  // If articles object doesnt exist or if it's empty when a user doesn't have saved articles,
  // throw error that will trigger Error Boundary
  if (
    !articles ||
    (articles && Object.keys(articles).length === 0 && !userHasArticles)
  ) {
    throw new Error("No articles!")
  }

  const user = useUser()
  let articlesToShow

  let heapAPI
  if (isBrowser() && window.heap) {
    heapAPI = window.heap
  }

  if (articles) {
    articlesToShow = articles.map((a, i) => (
      <div
        key={i}
        className={`${user !== null ? "w-full lg:w-1/4" : "w-full lg:w-1/4"}`}
      >
        <div
          className={`bg-white relative rounded-xl h-32 md:h-64 shadow-md mb-4 hover:bg-secondary h-card ${
            i !== 0 ? "md:ml-4" : ""
          }`}
          id="article-tile"
          onClick={() =>
            heapAPI.track("App - Dashboard - Click - Resources", {
              article_name: RichText.asText(a.title),
            })
          }
        >
          <ArticleCard
            article={a}
            userHasArticles={userHasArticles}
            titleCap={70}
            fromDashboard={fromDashboard}
          />
        </div>
      </div>
    ))
  }

  return <div className="flex flex-wrap max-w-5xl">{articlesToShow}</div>
}

LedeArticles.propTypes = {
  articles: PropTypes.array,
  userHasArticles: PropTypes.bool,
  fromDashboard: PropTypes.bool,
}

LedeArticles.defaultProps = {
  articles: [],
  userHasArticles: false,
  fromDashboard: false,
}

export default LedeArticles
