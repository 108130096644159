import React from "react"
import PropTypes from "prop-types"
import ArticleCard from "./article-card"
import { useUser } from "../../context/user-context"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Featured = ({ featured, userHasArticles, fromDashboard }) => {
  const user = useUser()
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    swipeToSlide: true,
    className: "lantern-slider",
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  let articlesToShow

  if (featured) {
    articlesToShow = featured.map((a, i) => (
      <div
        key={i}
        className={`${
          user !== null
            ? "w-full lg:w-1/2 xl:w-1/3 px-2"
            : "w-full md:w-1/2 xl:w-1/3 px-2"
        }`}
      >
        <div
          className={`bg-white relative rounded-xl h-32 md:h-64 shadow-md mb-4 hover:bg-secondary h-card}`}
        >
          <ArticleCard
            article={a}
            userHasArticles={userHasArticles}
            titleCap={70}
            fromDashboard={fromDashboard}
          />
        </div>
      </div>
    ))
  }

  return (
    <>
      <div className="hidden md:flex">
        <Slider {...settings}>{articlesToShow} </Slider>
      </div>
      <div className="visible md:hidden flex flex-wrap"> {articlesToShow}</div>
    </>
  )
}

Featured.propTypes = {
  featured: PropTypes.array,
  userHasArticles: PropTypes.bool,
  fromDashboard: PropTypes.bool,
}

Featured.defaultProps = {
  featured: [],
  userHasArticles: false,
  fromDashboard: false,
}

export default Featured
