import React from "react"
import PropTypes from "prop-types"
import ArticleCard from "./article-card"
import Featured from "./featured"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import PartnerOffers from "../../components/partner-offers"
import ErrorBoundary from "../../components/error-boundary"

function ArticleSections({
  categories,
  featured,
  userHasArticles,
  bookmarks,
  partnerOffersTitle,
  partnerOffers,
}) {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    className: "category-slider",
  }

  // If categories doesn't exist or if it's empty, throw error that will trigger Error Boundary
  if (!categories || categories.length === 0) {
    throw new Error("No categories!")
  }

  const bookmarkSlice = bookmarks.slice(0, 4)

  const categoriesToShow = categories
    .filter((el) => el.data.featured)
    .sort((a, b) => {
      return a.data.priority - b.data.priority
    })

  return (
    <>
      <div className="lantern-container mt-0 mb-2">
        <div className="visible md:hidden flex flex-wrap">
          <Slider {...settings}>
            {categories.map((category) => (
              <Link
                to={`/category/${category.uid}`}
                key={category.id}
                className="rounded-lg text-sm text-center leading-normal bg-grey-light px-4 py-2 mt-2"
              >
                {category.data.name}
              </Link>
            ))}
          </Slider>
        </div>
        <div className="hidden md:flex flex-wrap">
          {categories.map((category) => (
            <Link
              to={`/category/${category.uid}`}
              key={category.id}
              className="rounded-lg text-sm leading-normal bg-grey-light px-4 py-2 mr-2 mt-2"
            >
              {category.data.name}
            </Link>
          ))}
        </div>
      </div>
      <div className="pt-4">
        <div className="lantern-container mb-8">
          <h2 className="text-3xl font-extrabold leading-normal mb-4">
            Featured Articles
          </h2>
          <Featured
            featured={featured}
            userHasArticles={userHasArticles}
            fromDashboard={true}
          />
        </div>
      </div>

      {bookmarks.length > 0 ? (
        <div className="py-4 mt-16 bg-grey-light">
          <div className="lantern-container mb-8">
            <div className="flex  items-center ">
              <h2 className="text-3xl font-extrabold leading-normal flex-grow mb-4">
                My Bookmarks
              </h2>
              <div className="text-primary">
                <Link
                  className="text-base flex items-center font-semibold text-primary mb-2"
                  to="/category/my-bookmarks"
                >
                  {bookmarks.length > 4 && (
                    <>
                      <span>Show all</span>
                      <i className="material-icons text-primary">
                        chevron_right
                      </i>
                    </>
                  )}
                </Link>
              </div>
            </div>

            <div className="flex flex-col md:flex-row md:space-x-4">
              {bookmarkSlice.map((article, i) => (
                <div
                  key={i}
                  className={`w-full md:w-1/4 bg-white relative rounded-xl h-32 md:h-64 shadow-md mb-4 hover:bg-secondary`}
                >
                  <ArticleCard
                    article={article}
                    titleCap={70}
                    userHasArticles={userHasArticles}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}

      {categoriesToShow.map((category, i) => {
        if (category.article_response.results.length > 0) {
          return (
            <div
              className={`py-4 ${
                i === 0 && bookmarks.length > 0 ? "mt-8" : ""
              }`}
              key={category.id}
            >
              {/* Partner Offers Section */}
              {i === 2 && partnerOffers?.length > 0 && (
                <div className="pb-8">
                  <div className="bg-grey-light w-full pt-4 pb-2 mt-8">
                    <div className="lantern-container mb-0">
                      <h2 className="text-3xl font-extrabold leading-tight pb-2">
                        {partnerOffersTitle}
                      </h2>
                      <ErrorBoundary>
                        <PartnerOffers
                          partnerOffers={partnerOffers}
                          heapEvent={{
                            eventDomain: "App",
                            eventLocation: "Articles",
                            eventAction: "Click",
                            eventActionName: "Partner Offers",
                          }}
                        />
                      </ErrorBoundary>
                    </div>
                  </div>
                </div>
              )}
              <div className="lantern-container mb-0">
                <h2 className="text-3xl font-extrabold leading-tight pb-2">
                  {category.data.name}
                </h2>
                <p className="text-base leading-normal mb-6">
                  {category.data.description[0].text}
                </p>
                <div className="flex flex-col md:flex-row md:space-x-4">
                  {category.article_response.results.map((article, i) => (
                    <div
                      key={i}
                      className={`w-full bg-white relative rounded-xl shadow-md mb-4 hover:bg-secondary`}
                    >
                      <ArticleCard
                        article={article}
                        titleCap={70}
                        userHasArticles={userHasArticles}
                      />
                    </div>
                  ))}
                </div>
                <div className="text-primary">
                  <Link
                    className="text-base flex items-center font-semibold text-primary"
                    to={`/category/${category.uid}`}
                  >
                    <span>See all</span>
                    <i className="material-icons text-primary">chevron_right</i>
                  </Link>
                </div>
              </div>
            </div>
          )
        } else {
          return null
        }
      })}
    </>
  )
}

ArticleSections.propTypes = {
  categories: PropTypes.array.isRequired,
  articles: PropTypes.array,
  userHasArticles: PropTypes.bool,
  fromDashboard: PropTypes.bool,
  featured: PropTypes.array,
  bookmarks: PropTypes.array,
  partnerOffersTitle: PropTypes.string,
  partnerOffers: PropTypes.array,
}

ArticleSections.defaultProps = {
  articles: [],
  userHasArticles: false,
  fromDashboard: false,
  featured: [],
  bookmarks: [],
  partnerOffersTitle: "",
  partnerOffers: [],
}

export default ArticleSections
